import React, {useState, useEffect, useContext } from "react";
import { AddEditPortafolioForm } from "../components/AddEditPortafolioForm/AddEditPortafolioForm";
import { ModalBasic } from "../components/Common";
import {HeaderPage} from "../components/HeaderPage";
import Lista from "../components/portafolio/Lista";
import { FirebaseContext } from "../firebase";

const Portafolio = () => {
   const { firebase } = useContext(FirebaseContext);
   const [portafolio, guardarPortafolio] = useState([]);
   const [loading, setLoading] = useState(true);
   const [showModal, setShowModal] = useState(false);
   const [titleModal, setTitleModal] = useState(null);
   const [refetch, setRefetch] = useState(false);
   const [contentModal, setContentModal] = useState(null);
   const openCloseModal = () => setShowModal((prev) => !prev);
   const onRefetch = () => setRefetch((prev) => !prev);

   useEffect(() => {
     setLoading(true);
     const obtenerPortafolio = async () => {
       firebase.db
         .collection("portafolio")
         .onSnapshot(controlarSnapshotPortafolio);
     };
     obtenerPortafolio();
   }, []);
   //====Snapshot permite utilizar la base de datos en tiempo real======

   function controlarSnapshotPortafolio(snapshot) {
     const portafolio = snapshot.docs.map((doc) => {
       return {
         id: doc.id,
         ...doc.data(),
       };
     });
     //almacenamos los resultados en el state
     guardarPortafolio(portafolio);
     setLoading(false);
   }
   const addObra = () => {
     setTitleModal("Nueva Obra");
     setContentModal(
       <AddEditPortafolioForm onClose={openCloseModal} onRefetch={onRefetch} />
     );
     openCloseModal();
   };
   const updatePortafolio = (data) => {
     setTitleModal("Actualizar Portafolio");
     setContentModal(
       <AddEditPortafolioForm
         portafolio={data}
         onClose={openCloseModal}
         onRefetch={onRefetch}
       />
     );
     openCloseModal();
   };
        
  return(
      <>
        <HeaderPage
          title="Portafolio"
          btnTitle="Nueva Obra"
          btnClick={addObra}
        />
        <Lista portafolio={portafolio} updatePortafolio={updatePortafolio} />
        <ModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      </>
  );
};

export default Portafolio;
