import React, {useState, useEffect, useContext } from 'react';
import Lista from '../components/cartelera/Lista';
import { HeaderPage } from "../components/HeaderPage";
import {AddEditCarteleraForm} from '../components/AddEditCarteleraForm'
import { FirebaseContext } from "../firebase"; 
import { ModalBasic } from "../components/Common";

const Cartelera = () => {

      const { firebase } = useContext(FirebaseContext);
      const [cartelera, guardarCartelera] = useState([]);
      const [loading, setLoading] = useState(true);
      const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
      const [refetch, setRefetch] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const openCloseModal = () => setShowModal((prev) => !prev);
    const onRefetch = () => setRefetch((prev) => !prev);





     useEffect(() => {
       setLoading(true);
       const obtenerCartelera = async () => {
         firebase.db
           .collection("carteleta")
           .orderBy("fecha", "asc")
           .onSnapshot(controlarSnapshotCartelera);
       };
       obtenerCartelera();
     }, []);
     //====Snapshot permite utilizar la base de datos en tiempo real======

     function controlarSnapshotCartelera(snapshot) {
       const cartelera = snapshot.docs.map((doc) => {
         return {
           id: doc.id,
           ...doc.data(),
         };
       });
       //almacenamos los resultados en el state
       guardarCartelera(cartelera);
       setLoading(false);
     }
    const addObra = () => {
      setTitleModal("Nueva Obra");
      setContentModal(
        <AddEditCarteleraForm onClose={openCloseModal} onRefetch={onRefetch} />
      );
      openCloseModal();
    };
  const updateCartelera = (data) => {

    setTitleModal("Actualizar cartelera");
    setContentModal(<AddEditCarteleraForm cartelera={data} onClose={openCloseModal} onRefetch={onRefetch} />);
    openCloseModal();
  }
        
    
    return (
      <>
        <HeaderPage
          title="Cartelera"
          btnTitle="Nueva función"
          btnClick={addObra}
        />
        <Lista cartelera={cartelera} updateCartelera={updateCartelera}/>
        <ModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      </>
    );
}

export default Cartelera;