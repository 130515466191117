import React, { useState, useEffect, createContext } from "react";
import { setEmail, getEmail, removeEmail } from "./token";

export const AuthContext = createContext({
  auth: undefined,
  login: () => null,
  logout: () => null,
});

export function AuthProvider(props) {
  const { children } = props;
  const [auth, setAuth] = useState(undefined);
  //const { getMe } = useUser();
    
    useEffect(() => {
      (async () => {
        const email = getEmail();
        if (email) {
          setAuth({ email });
        } else {
          setAuth(null);
        }
      })();
    }, []);

    const loginAdm = async (email) => {
      setEmail(email);
      //const me = await getMe(email);
      setAuth({ email });
      console.log(email, "desde el context--------------------------");
    };

  const logout = () => {
    if (auth) {
      removeEmail();
      setAuth(null);
    }
  };

  const valueContext = {
    auth,
    loginAdm,
    logout
  };

  if (auth === undefined) return null;

  return (
    <AuthContext.Provider value={valueContext}>{children}</AuthContext.Provider>
  );
}
