import React, { useState, useContext } from "react";
import { Form, Button, TextArea } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AddEditBannerForm.scss";
import FileUploader from "react-firebase-file-uploader";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";




export function AddEditBannerForm(props) {
  
  const { onClose, onRefetch, banner } = props;
  console.log('desde el inicio',banner);
  //============Estado inicial para las imagenes=============

  const [subiendo, guardarSubiendo] = useState(false);

  const [progreso, guardarProgreso] = useState(0);

  const [urlImagen, guardarUrlImagen] = useState("");

  const { firebase } = useContext(FirebaseContext);

  const formik = useFormik({
    initialValues: initialValues(banner),
    //validationSchema: Yup.object(newSchame()),

    onSubmit: async  (formValue) => {
      console.log(formValue, 'el formulario');
       try {
         if (banner) {
           console.log(urlImagen.length);
          if (urlImagen.length > 0) formValue.imgPrincipal = urlImagen;
           await firebase.db
             .collection("banner")
             .doc(banner.id)
             .update(formValue);
           Swal.fire({
             position: "top-end",
             icon: "success",
             title: "Banner actualizado correctamente",
             showConfirmButton: false,
             timer: 1500,
           });
           onRefetch();
           onClose();
        
         } else {
     
           formValue.imgPrincipal = urlImagen;
           await firebase.db.collection("banner").add(formValue);
           Swal.fire({
             position: "top-end",
             icon: "success",
             title: "Banner creado correctamente",
             showConfirmButton: false,
             timer: 1500,
           });
           onRefetch();
           onClose();
           //REDIRECCIONAR
         }
      } catch (error) {
        console.log(error);
      }
    },
  });

  //============Todo para las imagenes===================

  const handleUploadStart = () => {
    guardarProgreso(0);
    guardarSubiendo(true);
  };
  const handleUploadError = (error) => {
    guardarSubiendo(false);
    console.log(error);
  };
  const handleUploadSuccess = async (nombre) => {
    guardarProgreso(100);
    guardarSubiendo(false);

    //=======Almacenar la Url de la imagen=======
    const url = await firebase.storage
      .ref("Banner")
      .child(nombre)
      .getDownloadURL();

    console.log(url);
    guardarUrlImagen(url);
  };

  const handleProgress = (progreso) => {
    guardarProgreso(progreso);
    console.log(progreso);
  };

  return (
    <Form className="add-edit-cartelera-form" onSubmit={formik.handleSubmit}>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="imagen"
      >
        Imagen (tamaño 1534 × 815px)
      </label>
      <FileUploader
        accept="image/*"
        id="imgPrincipal"
        randomizeFilename
        storageRef={firebase.storage.ref("Banner")}
        onUploadStart={handleUploadStart}
        onUploadError={handleUploadError}
        onUploadSuccess={handleUploadSuccess}
        onProgress={handleProgress}
      />
      {subiendo && (
        <div className="h-12 relative w-full border">
          <div
            className="bg-green-500 absolute left-0 top-0 text-white px-2 text-sm h-12 flex items-center"
            style={{ width: `${progreso}%` }}
          >
            {progreso}%
          </div>
        </div>
      )}
      {urlImagen && (
        <p className="bg-green-500 text-white p-3 text-center my-5">
          La imagen subio correctamente
        </p>
      )}
      <Button
        type="submit"
        primary
        fluid
         content={banner ? "Actualizar" : "Guardar"}
      />
    </Form>
  );
}

function initialValues(banner) {
  return {
    imgPrincipal: banner?.imgPrincipal || "",
  };
}

