import React, { useContext, useState } from "react";
import './LoginAdmin.scss';
import { Button, Form } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FirebaseContext } from "../../firebase";
import { useAuth } from "../../hooks/useAuth";



export default function LoginAdmin() {

  const { loginAdm } = useAuth();

  console.log(useAuth());
  const { firebase } = useContext(FirebaseContext);

  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");

  const [error, setError] = React.useState(null);
  const [esLogin, setLogin] = React.useState(true);

  const [user, setUser] = React.useState(null);

   const formik = useFormik({
     initialValues: intialValues(),
     validationSchema: Yup.object(validationSchema()),
     onSubmit: async (formValue) => {
       console.log(formValue);
       login(formValue);
     },
   });
  
  const login = React.useCallback(
    async (formValue) => {
      console.log(formValue.email, 'entra+++++++++');
      try {
        const res = await firebase.auth.signInWithEmailAndPassword(
          formValue.email,
          formValue.password
        );
        console.log(res, 'respuesta-------------');
        setError(null);
        setPass("");
        setEmail("");
        loginAdm(formValue.email);
      } catch (error) {
        console.log(error);
        if (error.code === "auth/wrong-password") {
          setError("Ups... contraseña errada ");
        }
        if (error.code === "auth/user-not-found") {
          setError("Ups... email invalido ");
        }
      } 
    },
    []
  );
  
    return (
      <div class="login">
        <h1>Login</h1>
        <fieldset>
          <legend class="legend">Iniciar</legend>
          <Form className="login-form-admin" onSubmit={formik.handleSubmit}>
            <Form.Input
              name="email"
              placeholder="Correo electronico"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
            <Form.Input
              name="password"
              type="password"
              placeholder="Contraseña"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
            />
            <Button type="submit" content="Iniciar sesión" primary fluid />
          </Form>
        </fieldset>
        <div class="feedback">
          login successful <br />
          redirecting...
        </div>
      </div>
    );
}


function intialValues() {
  return {
    email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
  };
}