import React from "react";
import Navigation from "./routes/navigation";
import firebase, { FirebaseContext } from "./firebase";
import { AuthProvider } from "./context/AuthContext";

export default function App() {
  return (
    <FirebaseContext.Provider value={{ firebase }}>
      <AuthProvider>
        <Navigation />
      </AuthProvider>
    </FirebaseContext.Provider>
  );
}
