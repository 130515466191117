import React, { useState, useContext } from "react";
import { Form, Button, TextArea } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AddEditPortafolioForm.scss";
import FileUploader from "react-firebase-file-uploader";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";

export function AddEditPortafolioForm(props) {
  const { onClose, onRefetch, portafolio } = props;
  console.log("desde el inicio", portafolio);
  //============Estado inicial para las imagenes=============

  const [subiendo, guardarSubiendo] = useState(false);

  const [progreso, guardarProgreso] = useState(0);

  const [urlImagen, guardarUrlImagen] = useState("");

  const { firebase } = useContext(FirebaseContext);

  const formik = useFormik({
    initialValues: initialValues(portafolio),
    validationSchema: Yup.object(newSchame()),

    onSubmit: async (formValue) => {
      try {
          if (portafolio) {
              if (urlImagen.length > 0) formValue.imgPrincipal = urlImagen;
          await firebase.db
            .collection("portafolio")
            .doc(portafolio.id)
            .update(formValue);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Función actualizada correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
          onRefetch();
          onClose();
        } else {
          console.log("no portafolio");

          formValue.imgPrincipal = urlImagen;
          await firebase.db.collection("portafolio").add(formValue);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Función creada correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
          onRefetch();
          onClose();
          //REDIRECCIONAR
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  //============Todo para las imagenes===================

  const handleUploadStart = () => {
    guardarProgreso(0);
    guardarSubiendo(true);
  };
  const handleUploadError = (error) => {
    guardarSubiendo(false);
    console.log(error);
  };
  const handleUploadSuccess = async (nombre) => {
    guardarProgreso(100);
    guardarSubiendo(false);

    //=======Almacenar la Url de la imagen=======
    const url = await firebase.storage
      .ref("portafolio")
      .child(nombre)
      .getDownloadURL();

    console.log(url);
    guardarUrlImagen(url);
  };

  const handleProgress = (progreso) => {
    guardarProgreso(progreso);
    console.log(progreso);
  };

  return (
    <Form className="add-edit-portafolio-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="titulo"
        placeholder="Titulo de la obra"
        value={formik.values.titulo}
        onChange={formik.handleChange}
        error={formik.errors.titulo}
      />
      <Form.TextArea
        name="detalle"
        placeholder="Detalle de la obra"
        value={formik.values.detalle}
        onChange={formik.handleChange}
        error={formik.errors.detalle}
      />

      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="imagen"
      >
        Imagen (tamaño 300 X 300px)
      </label>
      <FileUploader
        accept="image/*"
        id="imgPrincipal"
        randomizeFilename
        storageRef={firebase.storage.ref("portafolio")}
        onUploadStart={handleUploadStart}
        onUploadError={handleUploadError}
        onUploadSuccess={handleUploadSuccess}
        onProgress={handleProgress}
      />
      {subiendo && (
        <div className="h-12 relative w-full border">
          <div
            className="bg-green-500 absolute left-0 top-0 text-white px-2 text-sm h-12 flex items-center"
            style={{ width: `${progreso}%` }}
          >
            {progreso}%
          </div>
        </div>
      )}
      {urlImagen && (
        <p className="bg-green-500 text-white p-3 text-center my-5">
          La imagen subio correctamente
        </p>
      )}
      <Button
        type="submit"
        primary
        fluid
        content={portafolio ? "Actualizar" : "Guardar"}
      />
    </Form>
  );
}

function initialValues(portafolio) {
  return {
    titulo: portafolio?.titulo || "",
    detalle: portafolio?.detalle || "",
    imgPrincipal: portafolio?.imgPrincipal || "",
  };
}

function newSchame() {
  return {
    titulo: Yup.string().required("El nombre del producto es obligatorio"),
    detalle: Yup.string().required("El precio del producto es obligatorio")
  };
}
