import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './routesAdmin';
import { map } from 'lodash';
export default function Navigation() {
    console.log(routes);
    return (
      <BrowserRouter>
        <Routes>
          {map(routes, (route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <route.layout>
                  <route.component />
                </route.layout>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    );
}
