import React from 'react';
import LoginAdmin from '../../pages/LoginAdmin/LoginAdmin';
import { useAuth } from '../../hooks/useAuth';
import "./AdminLayout.scss";
import { TopMenu } from '../../TopMenu';
import { SideMenu } from "../../SideMenu";

export default function AdminLayout(props) {
    const { children } = props;
    const { auth } = useAuth();

  if (!auth) return <LoginAdmin />;
  return (
    <div className="admin-layout">
      <div className="admin-layout__menu">
        <TopMenu />
      </div>
      <div className="admin-layout__main-content-children">
        <SideMenu>{children}</SideMenu>
      </div>
    </div>
  );
}
