import React, { useContext } from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";
import { FirebaseContext } from "../../firebase";
import './Lista.scss';



 
const Lista = (props) => {
  const { banner, updateBanner} = props;
    const { firebase } = useContext(FirebaseContext);

    return (
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Imagen</Table.HeaderCell>
            <Table.HeaderCell>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {banner.map((b) => (
          <Table.Body key={b.id}>
            <Table.Row>
              <Table.Cell>
                <img
                  src={b.imgPrincipal}
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%" }}
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button icon onClick={() => updateBanner(b)}>
                  <Icon name="pencil" />
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
      </Table>
    );
}


export default Lista;