import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
//import { useAuth } from "../../../hooks";
import "./SideMenu.scss";

export function SideMenu(props) {
  const { children } = props;
 const { pathname } = useLocation();

  return (
    <div className="side-menu-admin">
      <MenuLeft pathname={pathname} />
      <div className="content">{children}</div>
    </div>
  );
}

function MenuLeft(props) {
  const { pathname } = props;
  //const { auth } = useAuth();

  return (
    <Menu fixed="left" borderless className="side" vertical>
      
      <Menu.Item as={Link} to={"/banner"}>
        <Icon name="home" /> Banner
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={"/info"}
      >
        <Icon name="info" /> Info
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={"/cartelera"}
      >
        <Icon name="history" /> Cartelera
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={"/portafolio"}
      >
        <Icon name="folder" /> Portafolio
      </Menu.Item>

     {/*  <Menu.Item
        as={Link}
        to={"/footer"}
      >
        <Icon name="cart" /> Footer
      </Menu.Item> */}

     {/*  {auth.me?.is_staff && (
        <Menu.Item
          as={Link}
          to={"/admin/users"}
          active={pathname === "/admin/users"}
        >
          <Icon name="users" /> Usuarios
        </Menu.Item>
      )} */}
    </Menu>
  );
}
