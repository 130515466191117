import React, {useState, useEffect, useContext } from 'react';
import Lista from '../components/about/Lista';
import { HeaderPage } from "../components/HeaderPage";
import {AddEditAboutForm} from '../components/AddEditAboutForm'
import { FirebaseContext } from "../firebase"; 
import { ModalBasic } from "../components/Common";

const Info = () => {

      const { firebase } = useContext(FirebaseContext);
      const [about, guardarAbout] = useState([]);
      const [loading, setLoading] = useState(true);
      const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
      const [refetch, setRefetch] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const openCloseModal = () => setShowModal((prev) => !prev);
    const onRefetch = () => setRefetch((prev) => !prev);





     useEffect(() => {
       setLoading(true);
       const obtenerAbout = async () => {
         firebase.db
           .collection("about")
           .onSnapshot(controlarSnapshotAbout);
       };
       obtenerAbout();
     }, []);
     //====Snapshot permite utilizar la base de datos en tiempo real======

     function controlarSnapshotAbout(snapshot) {
       const about = snapshot.docs.map((doc) => {
         return {
           id: doc.id,
           ...doc.data(),
         };
       });
       //almacenamos los resultados en el state
       guardarAbout(about);
       setLoading(false);
     }
    const addAbout = () => {
      setTitleModal("Nuevo dato");
      setContentModal(
        <AddEditAboutForm onClose={openCloseModal} onRefetch={onRefetch} />
      );
      openCloseModal();
    };
  const updateAbout = (data) => {

    setTitleModal("Actualizar Datos");
    setContentModal(<AddEditAboutForm about={data} onClose={openCloseModal} onRefetch={onRefetch} />);
    openCloseModal();
  }
        
    
    return (
      <>
       {/*  <HeaderPage
          title="Cartelera"
          btnTitle="Nueva función"
          btnClick={addAbout}
        /> */}
        <Lista about={about} updateAbout={updateAbout}/>
        <ModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      </>
    );
}

export default Info;