import React, { useState, useContext } from "react";
import { Form, Button, TextArea } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AddEditAboutForm.scss";
import FileUploader from "react-firebase-file-uploader";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";




export function AddEditAboutForm(props) {
  
  const { onClose, onRefetch, about } = props;
  //============Estado inicial para las imagenes=============

  const [subiendo, guardarSubiendo] = useState(false);

  const [progreso, guardarProgreso] = useState(0);

  const [urlImagen, guardarUrlImagen] = useState("");

  const { firebase } = useContext(FirebaseContext);

  const formik = useFormik({
    initialValues: initialValues(about),
    //validationSchema: Yup.object(newSchame()),

    onSubmit: async  (formValue) => {
      console.log(formValue, 'el formulario');
       try {
         if (about) {
           console.log(urlImagen.length);
           await firebase.db
             .collection("about")
             .doc(about.id)
             .update(formValue);
           Swal.fire({
             position: "top-end",
             icon: "success",
             title: "Datoser actualizados correctamente",
             showConfirmButton: false,
             timer: 1500,
           });
           onRefetch();
           onClose();
        
         } else {
     
           await firebase.db.collection("about").add(formValue);
           Swal.fire({
             position: "top-end",
             icon: "success",
             title: "Banner creado correctamente",
             showConfirmButton: false,
             timer: 1500,
           });
           onRefetch();
           onClose();
           //REDIRECCIONAR
         }
      } catch (error) {
        console.log(error);
      }
    },
  });


  

  return (
    <Form className="add-edit-cartelera-form" onSubmit={formik.handleSubmit}>
      
      <Form.Input
        name="link"
        placeholder="Enlace de youtube"
        value={formik.values.link}
        onChange={formik.handleChange}
        error={formik.errors.link}
      />
      <Form.Input
        name="title"
        placeholder="Titulo de la sección"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.errors.title}
      />
      <Form.TextArea
        name="descripcion"
        placeholder="Descripción de la sección"
        value={formik.values.descripcion}
        onChange={formik.handleChange}
        error={formik.errors.descripcion}
      />
       <Form.TextArea
        name="about1"
        placeholder="Primer texto de la sección"
        value={formik.values.about1}
        onChange={formik.handleChange}
        error={formik.errors.about1}
      />
       <Form.TextArea
        name="about2"
        placeholder="Segundo texto de la sección"
        value={formik.values.about2}
        onChange={formik.handleChange}
        error={formik.errors.about2}
      />
    
      <Button
        type="submit"
        primary
        fluid
         content={about ? "Actualizar" : "Guardar"}
      />
    </Form>
  );
}

function initialValues(about) {
  return {
    link: about?.link || "",
    title: about?.title || "",
    descripcion: about?.descripcion || "",
    about1: about?.about1 || "",
    about2: about?.about2 || "",
  };
}

