import AdminLayout from '../layouts/adminLayout/AdminLayout';
import Home from '../pages/home';
import LoginAdmin from "../pages/LoginAdmin/LoginAdmin";
import Error404 from '../pages/error404';
import Banner from '../pages/Banner';
import Info from '../pages/Info';
import Portafolio from '../pages/Portafolio';
import Cartelera from '../pages/Cartelera';
import Footer from '../pages/Footer';


const routes = [
  {
    path: "login",
    layout: AdminLayout,
    component: LoginAdmin,
    exact: true,
  },
  {
    path: "/",
    layout: AdminLayout,
    component: Home,
    exact: true,
  },
  {
    layout: AdminLayout,
    component: Error404,
  },
  {
    path: "banner",
    layout: AdminLayout,
    component: Banner,
    exact: true,
  },
  {
    path: "info",
    layout: AdminLayout,
    component: Info,
    exact: true,
  },
  {
    path: "portafolio",
    layout: AdminLayout,
    component: Portafolio,
    exact: true,
  },
  {
    path: "cartelera",
    layout: AdminLayout,
    component: Cartelera,
    exact: true,
  },
  {
    path: "footer",
    layout: AdminLayout,
    component: Footer,
    exact: true,
  },
];

export default routes;