import React, { useState, useContext } from "react";
import { Form, Button, TextArea } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AddEditCarteleraForm.scss";
import FileUploader from "react-firebase-file-uploader";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";




export function AddEditCarteleraForm(props) {
  
  const { onClose, onRefetch, cartelera } = props;
  console.log('desde el inicio',cartelera);
  //============Estado inicial para las imagenes=============

  const [subiendo, guardarSubiendo] = useState(false);

  const [progreso, guardarProgreso] = useState(0);

  const [urlImagen, guardarUrlImagen] = useState("");
  const [imagenDetalle, guardarUrlImagenDetalle] = useState("");
  const { firebase } = useContext(FirebaseContext);

  const formik = useFormik({
    initialValues: initialValues(cartelera),
    validationSchema: Yup.object(newSchame()),

    onSubmit: async  (formValue) => {
       try {
         if (cartelera) {
           console.log(urlImagen.length);
          if (urlImagen.length > 0) formValue.imgPrincipal = urlImagen;
          if (imagenDetalle.length > 0) formValue.imagenDetalle = imagenDetalle;
           await firebase.db
             .collection("carteleta")
             .doc(cartelera.id)
             .update(formValue);
           Swal.fire({
             position: "top-end",
             icon: "success",
             title: "Función actualizada correctamente",
             showConfirmButton: false,
             timer: 1500,
           });
           onRefetch();
           onClose();
        
         } else {
           console.log("no cartelera");
           formValue.imgPrincipal = urlImagen;
           formValue.imagenDetalle = imagenDetalle;
           await firebase.db.collection("carteleta").add(formValue);
           Swal.fire({
             position: "top-end",
             icon: "success",
             title: "Función creada correctamente",
             showConfirmButton: false,
             timer: 1500,
           });
           onRefetch();
           onClose();
           //REDIRECCIONAR
         }
      } catch (error) {
        console.log(error);
      }
    },
  });

  //============Todo para las imagenes===================

  const handleUploadStart = () => {
    guardarProgreso(0);
    guardarSubiendo(true);
  };
  const handleUploadError = (error) => {
    guardarSubiendo(false);
    console.log(error);
  };
  const handleUploadSuccess = async (nombre) => {
    guardarProgreso(100);
    guardarSubiendo(false);

    //=======Almacenar la Url de la imagen=======
    const url = await firebase.storage
      .ref("cartelera")
      .child(nombre)
      .getDownloadURL();

      const urlDetalle = await firebase.storage
      .ref("cartelera")
      .child(nombre)
      .getDownloadURL();
    console.log(url);
    guardarUrlImagen(url);
    guardarUrlImagenDetalle(urlDetalle);
  };

  const handleProgress = (progreso) => {
    guardarProgreso(progreso);
    console.log(progreso);
  };

  return (
    <Form className="add-edit-cartelera-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="titulo"
        placeholder="Titulo de la obra"
        value={formik.values.titulo}
        onChange={formik.handleChange}
        error={formik.errors.titulo}
      />
      <Form.Input
        name="fechas"
        placeholder="fecha o fechas para mostrar"
        value={formik.values.fechas}
        onChange={formik.handleChange}
        error={formik.errors.fechas}
      />
      <Form.Input
        name="enlace"
        placeholder="enlace para comprar entrada"
        value={formik.values.enlace}
        onChange={formik.handleChange}
        error={formik.errors.enlace}
      />
      <Form.Input
        name="slug"
        placeholder="Slug.  --> el mismo titulo sin espacios"
        value={formik.values.slug}
        onChange={formik.handleChange}
        error={formik.errors.slug}
      />
      <Form.TextArea
        name="detalle"
        placeholder="Detalle de la obra"
        value={formik.values.detalle}
        onChange={formik.handleChange}
        error={formik.errors.detalle}
      />
      <label>fecha Inicio. si es por varios dias el evento </label>
      <Form.Input
        name="fecha"
        type="date"
        placeholder="fecha Inicio. si es por varios dias el evento "
        value={formik.values.fecha}
        onChange={formik.handleChange}
        error={formik.errors.fecha}
      />
      <label>echa final. si es por varios dias el evento </label>

      <Form.Input
        name="fechaFin"
        type="date"
        placeholder="fecha final. si es por varios dias el evento "
        value={formik.values.fechaFin}
        onChange={formik.handleChange}
        error={formik.errors.fechaFin}
      />
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="imagen"
      >
        Imagen (tamaño 500 X 333px)
      </label>
      <FileUploader
        accept="image/*"
        id="imgPrincipal"
        randomizeFilename
        storageRef={firebase.storage.ref("cartelera")}
        onUploadStart={handleUploadStart}
        onUploadError={handleUploadError}
        onUploadSuccess={handleUploadSuccess}
        onProgress={handleProgress}
      />
      {subiendo && (
        <div className="h-12 relative w-full border">
          <div
            className="bg-green-500 absolute left-0 top-0 text-white px-2 text-sm h-12 flex items-center"
            style={{ width: `${progreso}%` }}
          >
            {progreso}%
          </div>
        </div>
      )}
      {urlImagen && (
        <p className="bg-green-500 text-white p-3 text-center my-5">
          La imagen subio correctamente
        </p>
      )}
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="imagen"
      >
        Imagen (tamaño 500 X 333px)
      </label>
      <FileUploader
        accept="image/*"
        id="imagenDetalle"
        randomizeFilename
        storageRef={firebase.storage.ref("cartelera")}
        onUploadStart={handleUploadStart}
        onUploadError={handleUploadError}
        onUploadSuccess={handleUploadSuccess}
        onProgress={handleProgress}
      />
      {subiendo && (
        <div className="h-12 relative w-full border">
          <div
            className="bg-green-500 absolute left-0 top-0 text-white px-2 text-sm h-12 flex items-center"
            style={{ width: `${progreso}%` }}
          >
            {progreso}%
          </div>
        </div>
      )}
      {urlImagen && (
        <p className="bg-green-500 text-white p-3 text-center my-5">
          La imagen subio correctamente
        </p>
      )}
      <Button
        type="submit"
        primary
        fluid
         content={cartelera ? "Actualizar" : "Guardar"}
      />
    </Form>
  );
}

function initialValues(cartelera) {
  return {
    titulo: cartelera?.titulo || "",
    fechas: cartelera?.fechas || "",
    enlace: cartelera?.enlace || "",
    slug: cartelera?.slug || "",
    detalle: cartelera?.detalle || "",
    fecha: cartelera?.fecha || "",
    fechaFin: cartelera?.fechaFin || "",
    imgPrincipal: cartelera?.imgPrincipal || "",
    imagenDetalle: cartelera?.imagenDetalle || "",
  };
}

function newSchame() {
  return {
      titulo: Yup.string().required("El nombre del producto es obligatorio"),
      detalle: Yup.string().required("El precio del producto es obligatorio"),
      enlace: Yup.string().required("La categoria del producto es obligatoria"),
      fechas: Yup.string().required(
        "la descripcion del producto es obligatoria")
  };
}

