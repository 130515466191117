import { EMAIL } from "../utils/constants";

export function setEmail(email) {
  localStorage.setItem(EMAIL, email);
}

export function getEmail() {
  return localStorage.getItem(EMAIL);
}

export function removeEmail() {
  localStorage.removeItem(EMAIL);
}
