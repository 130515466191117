import React from "react";
import { Icon, Menu } from "semantic-ui-react";
import { useAuth } from "../hooks/useAuth";
import "./TopMenu.scss";

export function TopMenu() {
 const { auth, logout } = useAuth();

  console.log(auth);
  const renderName = () => {
    if (auth.me?.first_name && auth.me?.last_name) {
      return `${auth.me.first_name} ${auth.me.last_name}`;
    }
    return auth.me?.email;
  };

  return (
    <Menu fixed="top" className="top-menu-admin">
      <Menu.Item className="top-menu-admin__logo">
        <p>Admin</p>
      </Menu.Item>

      <Menu.Menu position="right">
        <Menu.Item style={{ color: "#fff" }}>Hola, {auth.email}</Menu.Item>
        <Menu.Item onClick={logout}>
          <Icon style={{ color: "#fff" }} name="sign-out" />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}
