import React, {useState, useEffect, useContext } from 'react';
import Lista from '../components/banner/Lista';
import { HeaderPage } from "../components/HeaderPage";
import {AddEditBannerForm} from '../components/AddEditBannerForm'
import { FirebaseContext } from "../firebase"; 
import { ModalBasic } from "../components/Common";
const Banner = () => {
    const { firebase } = useContext(FirebaseContext);
      const [banner, guardarBanner] = useState([]);
      const [loading, setLoading] = useState(true);
      const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
      const [refetch, setRefetch] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const openCloseModal = () => setShowModal((prev) => !prev);
    const onRefetch = () => setRefetch((prev) => !prev);

    useEffect(() => {
        setLoading(true);
        const obtenerBanner = async () => {
          firebase.db
            .collection("banner")
            .onSnapshot(controlarSnapshotBanner);
        };
        obtenerBanner();
      }, []);
      //====Snapshot permite utilizar la base de datos en tiempo real======
 
      function controlarSnapshotBanner(snapshot) {
        const banner = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        //almacenamos los resultados en el state
        guardarBanner(banner);
        setLoading(false);
      }
     const addBanner = () => {
       setTitleModal("Nuevo banner");
       setContentModal(
         <AddEditBannerForm onClose={openCloseModal} onRefetch={onRefetch} />
       );
       openCloseModal();
     };
   const updateBanner = (data) => {
 
     setTitleModal("Actualizar banner");
     setContentModal(<AddEditBannerForm banner={data} onClose={openCloseModal} onRefetch={onRefetch} />);
     openCloseModal();
   }
    return (
    <>
    
    <Lista banner={banner} updateBanner={updateBanner}/>
    <ModalBasic
      show={showModal}
      onClose={openCloseModal}
      title={titleModal}
      children={contentModal}
    />
  </>
   )
}


export default Banner;