
//=========PRODUCCIÓN===========
/* const firebaseConfig = {
  apiKey: "AIzaSyDCAR56IhB2kpUBuEnKHEHNhxwKxHc9d-0",
  authDomain: "andresbravo-699dd.firebaseapp.com",
  projectId: "andresbravo-699dd",
  storageBucket: "andresbravo-699dd.appspot.com",
  messagingSenderId: "582059974333",
  appId: "1:582059974333:web:35c299b2caadcb7144a1d4",
  measurementId: "G-4L7P0S7F56",
}; */
const firebaseConfig = {
  apiKey: "AIzaSyDYPlvh72gj-X5gwGpcuLO_g_U_YAyRRbY",
  authDomain: "bravoperofeliz-f0bba.firebaseapp.com",
  projectId: "bravoperofeliz-f0bba",
  storageBucket: "bravoperofeliz-f0bba.appspot.com",
  messagingSenderId: "599987030599",
  appId: "1:599987030599:web:53928dff3b1b995cbb3f62",
  measurementId: "G-MHBR6K874J"
}
    export default firebaseConfig;