import React, { useContext } from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";
import { FirebaseContext } from "../../firebase";
import './Lista.scss';



 
const Lista = (props) => {
  const { about, updateAbout} = props;
    const { firebase } = useContext(FirebaseContext);
console.log(about, 'los dato============');
    return (
      <Table fixed>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell>Link</Table.HeaderCell>
            <Table.HeaderCell>Titulo</Table.HeaderCell>
            <Table.HeaderCell>Descripcion</Table.HeaderCell>
            <Table.HeaderCell>about 1</Table.HeaderCell>
            <Table.HeaderCell>About 2</Table.HeaderCell>
            <Table.HeaderCell>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        
        {about.map((a) => (
          <Table.Body key={a.id}>
            <Table.Row>
              <Table.Cell>{a.link}</Table.Cell>
              <Table.Cell>{a.title}</Table.Cell>
              <Table.Cell>{a.descripcion}</Table.Cell>
              <Table.Cell>{a.about1}</Table.Cell>
              <Table.Cell>{a.about2}</Table.Cell>
              <Table.Cell textAlign="right">
                <Button icon onClick={() => updateAbout(a)}>
                  <Icon name="pencil" />
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
        
      </Table>
    );
}


export default Lista;