import React, { useContext } from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";
import { FirebaseContext } from "../../firebase";
import './Lista.scss';



 
const Lista = (props) => {
  const { cartelera, updateCartelera } = props;
    const { firebase } = useContext(FirebaseContext);

  
  const eliminarObra = (id) => {

    Swal.fire({
      title: "Estas seguro de eliminar esta función?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        firebase.db.collection("carteleta").doc(id).delete();
        Swal.fire("Funciòn eliminada correctamente!", "", "success");
      }
    });
  }

    return (
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Imagen</Table.HeaderCell>
            <Table.HeaderCell>fecha</Table.HeaderCell>
            <Table.HeaderCell>Detalle</Table.HeaderCell>
            <Table.HeaderCell>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {cartelera.map((obra) => (
          <Table.Body key={obra.id}>
            <Table.Row>
              <Table.Cell>{obra.titulo}</Table.Cell>
              <Table.Cell>
                <img
                  src={obra.imgPrincipal}
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%" }}
                />
              </Table.Cell>
              <Table.Cell>{obra.fechas}</Table.Cell>
              <Table.Cell>{obra.detalle.substr(0, 100)}...</Table.Cell>
              <Table.Cell textAlign="right">
                <Button icon onClick={() => updateCartelera(obra)}>
                  <Icon name="pencil" />
                </Button>
                <Button icon negative onClick={() => eliminarObra(obra.id)}>
                  <Icon name="close" />
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
      </Table>
    );
}


export default Lista;